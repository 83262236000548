import { ArrowLeftIcon } from '@heroicons/react/outline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import lessonsAPI from '../../api/lessons';
import Spinner from '../../components/Spinner';
import './style.css';

export default function ViewBlog() {
  const [data, setData] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    lessonsAPI.getLesson(params?.id).then((res: any) => {
      if (res.data) {
        setData(res.data);
        setLoading(false);
      }
    });
  },[]);
  return (
    <div className='content-viewer w-full relative h-screen'>
      <div className='flex items-center hover:opacity-75 cursor-pointer'
          onClick={() => navigate('/lessons')}
        >
          <ArrowLeftIcon className=' w-3 h-3 mr-1' />
          <div className=' text-sm text-black py-2 '>Back</div>
        </div>
      {
        loading ? 
          <Spinner />
          :
          <div className='bg-white  rounded-lg flex flex-col items-center justify-center p-10'>
            <div className='py-8 text-sm max-w-4xl max-h-[74vh] overflow-scroll'>
              <h1 className='text-2xl text-center max-w-4xl m-auto font-semibold mb-8 mt-8'>{data?.title}</h1>

               <img className=' w-full ' onError={(event: any) => event.target.style.display = 'none'}  src={`${process.env.REACT_APP_API_URL}/lesson/banner_desktop/${data?.slug}`} />

              <div className='text-xs text-gray-500 my-4'>
                <div>Author: <span className=' capitalize'>Capshort Research</span></div>
                <div>{moment(data?.postedBy?.updatedAt).format('lll')}</div>
              </div>
              { data?.contents.find((item:any) => item.heading !== '') &&
                <div className='p-4 border rounded-ld border-dotted w-fit mb-8 min-w-[300px]'>
                  <h2 className=' text-sm font-medium'>Table of Content</h2>
                  {
                    data?.contents?.map((item: any) => {
                      if (!item.heading) return;
                      return (
                        <h3 key={item._id} className=' text-sm font-normal italic  text-gray-500 my-2' id={item.idContent}><a href={`#${item.idContent}`}> {item.heading}</a></h3>
                      );
                    })
                  }
                </div>
              }
              {
                data?.contents?.map((item: any) => {
                  return (
                    <div key={item._id}>
                      {item.heading && <h2 className=' text-base font-medium py-3' id={item.idContent}>{item?.heading}</h2>}
                      <p className='px-4'
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
      }
    </div>
    
  );
}
