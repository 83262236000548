/* eslint-disable react/react-in-jsx-scope */
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import {
    TrashIcon,
    PencilIcon
} from '@heroicons/react/outline';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const MenuContextOptions = ({deleteTag, tag, setSelectedTag} : any) => {
    
    const MenuList = [
        {
            name: `Edit`,
            icons: <PencilIcon  className=' w-4 h-4'/>,
            function: () => setSelectedTag(tag),
            isDisplay: true
        },
        {
            name: "Delete",
            icons: <TrashIcon  className='w-4 h-4'/>,
            function: () => deleteTag(tag._id),
        },
    ];

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="flex justify-center items-center text-ooolab_dark_1 focus:outline-none w-6 h-6 rounded-full">
                            <DotsVerticalIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="z-9999 shadow-sm origin-top-right absolute right-full -top-full mt-2 w-40 rounded-lg overflow-hidden divide-y divide-gray-100 focus:outline-none"
                        >
                            <div className="py-1">
                                {MenuList.map((i) => {
                                    return (
                                        <Menu.Item key={i.name}>
                                            {() => (
                                                <div
                                                    onClick={() => {
                                                        if (i.function) {
                                                            i.function();
                                                        }
                                                    }}
                                                    className={`flex items-center  px-4 w-full bg-white hover:bg-gray-50 cursor-pointer `}
                                                >
                                                    {i.icons}
                                                    <a
                                                        className={classNames(
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        {i.name}
                                                    </a>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default MenuContextOptions;
