/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/react-in-jsx-scope */
import SearchInput from '../../components/SearchInput';
import Table from './components/Table';
import { useDebouncedState } from '../../hooks/useDebounce';
import { PlusIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment, useEffect } from 'react';
import TagsAPI from '../../api/tags';
import { useNotifier } from 'react-headless-notifier';
import { SuccessNotification } from '../../components/Notification';
import { DangerNotification } from '../../components/Notification';
import TablePagination from '../../components/Pagination';
import { get } from 'lodash';

export default function index() {
  const [ keyword , setKeyword] = useDebouncedState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [tags, setTags] = useState({
    currentPage: 1,
    data: [],
    totalPages: 1
  });
  const [tagName, setNewTagName] = useState('');
  const { notify } = useNotifier();

  const getTags = (page = 1) => {
    setLoading(true);
    TagsAPI.getTags({ page, limit: 10, search: keyword }).then((res: any) => {
      setTags(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTags();
  }, [keyword]);

  const createTag = () => {
    if (get(selectedTag, '_id', false)) {
      TagsAPI.updateTag(get(selectedTag, '_id'), tagName).then((res: any) => {
        if (res.data) {
          getTags();
          setNewTagName('');
          setSelectedTag('');
          notify(
            <SuccessNotification
              message="Updated tag success!"
            />,
            {
              position: 'bottomRight',
              duration: 3000, 
            },
          );
        }
      }).catch(() => 
        notify(
          <DangerNotification
            message="Updated tag fail! Please try it again "
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        )
      )
      .finally(() => {
        setIsOpen(false);
        setLoading(false);
      });
    }
    else {
      TagsAPI.createTag(tagName).then((res: any) => {
        if (res.data) {
          getTags();
          setNewTagName('');
          notify(
            <SuccessNotification
              message="Create tag success!"
            />,
            {
              position: 'bottomRight',
              duration: 3000, 
            },
          );
        }
      }).catch(() => 
        notify(
          <DangerNotification
            message="Create tag fail! Please try it again "
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        )
      )
      .finally(() => {
        setIsOpen(false);
        setLoading(false);
      });
    }
  };

  const deleteTag = (id: string) => {
    setLoading(true);
    TagsAPI.deleteTag(id).then(() => {
      getTags();
      notify(
        <SuccessNotification
          message="Delete tag success!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      );
    }).catch(() => 
      notify(
        <DangerNotification
          message="Delete tag fail! Please try it again "
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )
    )
    .finally(() => {
      setLoading(false);
    });
  };

  const handlePagination = (nextPage: number) => {
    getTags(nextPage);
  };

  return (
    <div className='relative'>
      <div className='flex justify-between '>
        <SearchInput onSubmit={(e: any) => setKeyword(e)} />
        <button onClick={() => setIsOpen(true)} className=' text-xs text-white px-3 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'><PlusIcon className='w-4 h-4 mr-1'/> Add New Tag</button>
      </div>
      <div className='mt-3'>
        
      </div>
      <div className='h-[calc(100vh-200px)] overflow-scroll'>
        <Table data={tags.data} deleteTag={deleteTag} setSelectedTag={setSelectedTag}/>
      </div>
      <TablePagination 
        onClickPagination={handlePagination}
        perPage={10}
        total={+tags?.totalPages * 10}
      />
      <Transition appear show={isOpen || !!get(selectedTag, '_id', false)} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {setIsOpen(false); setSelectedTag(''); }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    {get(selectedTag, '_id', false) ? 'Edit Tag' : 'Add New Tag'}
                  </Dialog.Title>
                  <div className="mt-4">
                    <input defaultValue={get(selectedTag, 'name', '')} onChange={(e) => setNewTagName(e.target.value)}  className='w-full px-3 py-2 focus:outline-none rounded-lg border border-gray-200 text-xs'  placeholder='Enter Tag Name'/>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-xs font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={createTag}
                    >
                      {get(selectedTag, '_id', false) ? 'Save' : 'Add'}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {
      loading && (
        <div className='absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 z-30'>
          <svg className="h-6 w-6 animate-spin absolute " viewBox="3 3 18 18">
            <path
              className="fill-indigo-200"
              d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
            <path
              className="fill-indigo-800"
              d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
          </svg>
        </div>
      )
     }
    </div>
  );
}
