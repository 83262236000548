/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import { PlusIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Form from './Form';
import docAPI from '../../api/doc';
import { useNotifier } from 'react-headless-notifier';
import { SuccessNotification } from '../../components/Notification';
import { DangerNotification } from '../../components/Notification';
import Spinner from './components/Spinner';
import ViewBlog from './ViewBlog';

export default function TermOfService() {
  const [isOpenForm, setOpenForm] = useState(false);
  const { notify } = useNotifier();
  const [termOfServices, setTermOfService] = useState({})
  
  const [loading, setLoading] = useState(true)

  const getTermOfService = () => {
    setLoading(true)
    docAPI.getDoc('term-of-services').then((res: any) => {
      if (res.data) {
        setTermOfService(res.data)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getTermOfService()
  }, [])

  const updateTermOfService= (data: any) => {
    setLoading(true)
    docAPI.updateDoc(data)
    .then((res: any) => {
      if (res.data) {
        getTermOfService()
        setOpenForm(false)
        notify(
          <SuccessNotification
            message="Updated Term Of Service success!"
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      }
    })
    .catch(() => 
      notify(
        <DangerNotification
          message="Updated Term Of Service fail!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )
    ).finally(() => setLoading(false));
  };

  return (
    <div className='relative'>
      {
        isOpenForm ? 
        <Form update={updateTermOfService} termOfServices={termOfServices} setOpenForm={setOpenForm}/>
        :
        <div className='w-full'>
          <div className='flex w-full items-end justify-end mb-4'>
            <button onClick={() => setOpenForm(true)} className=' text-sm text-white px-3 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'><PlusIcon className='w-4 h-4 mr-1'/> Update</button>
          </div>
          <ViewBlog termOfServices={termOfServices} />
        </div>
      }
     {
      loading && <Spinner />
     }
    </div>
  )
}
