/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Label from '../../../components/Label';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import Editor from '../components/Editor';
import docAPI from '../../../api/doc';
import {get} from 'lodash';
import Spinner from '../components/Spinner';

interface Props {
  update: Function
  policy: any
  setOpenForm: Function
}

export default function Form(props: Props) {
  const { update, policy, setOpenForm } = props;
  const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      getValues,
      reset,
  } = useForm({mode: 'onChange'});

  const { fields, append } = useFieldArray({
    control,
    name: "contents"
  });
  const [currentBlog, setCurrentBlog] = useState({photo: undefined})
  const [loading, setLoading] = useState(false);

  const watchFieldArray = watch("contents");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  useEffect(() => {
    if(policy) {
      setLoading(true);
      docAPI.getDoc("privacy-policy")
      .then((res: any) => {
        reset({
          ...res.data,
          tags: res.data?.tags.map((tag: any) => ({label: tag?.name, value: tag?._id})) || []
        })
        setCurrentBlog({
          ...res.data,
        })
        setLoading(false);
      })
    }
  }, [])


  const onSubmit = (val: any) => {
    const params = {
      ...val,
      contents: (getValues('contents') || []).map((item: any) => {
        return {
          ...item,
          idContent: item.idContent !== '' ? item.idContent : item.heading.replaceAll(" ", "-") 
        }
      })
    }
    if (!params.photo?.[0]) delete params.photo
    update(params)
  }

  const addMoreContent = () => {
     append({heading: "", idContent: "", content: ""});
  }

  return (
    <div>
      <div className='flex items-center hover:opacity-75 cursor-pointer '
        onClick={() => setOpenForm(false)}
      >
        <ArrowLeftIcon className=' w-3 h-3 mr-1' />
        <div className=' text-sm text-black py-2 '>Back</div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" gap-x-8 gap-y-5 mt-2 bg-white rounded-lg max-h-[84vh] overflow-scroll py-4 px-14"
      >
        <div className='grid grid-cols-2 gap-5'>
          <div className=' col-span-2'>
            <div >
              <Label htmlFor="title">Title</Label>
              <input
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                  type="text"
                  id="title"
                  disabled
                  placeholder={"Enter Title"}
                  {...register('title', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="title"
                    as="p"
                />
            </div>
           
            <div className='mt-2'>
              <Label htmlFor="keywords">Keywords</Label>
              <textarea
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none "
                  id="keywords"
                  placeholder={"Enter Meta Kewords"}
                  maxLength={500}
                  {...register('keywords', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="keywords"
                    as="p"
                />
            </div>   
          </div>
          {/* <div className='mt-2 col-span-1'>
            <Label htmlFor="photo">Banner</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl flex justify-center items-center flex-col cursor-pointer relative'>
              <input
                  className='w-full h-full'
                  hidden
                  type="file"
                  accept="image/*"
                  size={1048576}
                  {...register('photo')}
                />
                {
                   ((policy && watch('photo')?.[0]) || (!policy && (watch('photo')?.[0]) || currentBlog.photo)) ?
                  <img className=' absolute object-contain origin-center w-full h-full' src={(watch(`photo`)?.[0] && URL?.createObjectURL(watch(`photo`)?.[0])) || `${process.env.REACT_APP_API_URL}/blog/photo/${policy.slug}` } />
                  :
                  <div className='italic'>
                    <p className=' text-xs text-gray-600 text-center'>Upload Banner Image </p>
                    <p className='text-xs text-gray-400 mt-1'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                    <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
                  </div>
                }
            </label>
          </div> */}
        </div>
        
        <div>
          <Label htmlFor="metaDescription">Meta Description</Label>
          <textarea
              className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none h-44"
              id="metaDescription"
              placeholder={"Enter Meta Description"}
              maxLength={500}
              {...register('metaDescription', {
                  required: {
                      value: true,
                      message: `This field is required!`
                  },
                  validate: {
                      shouldNotContainSpace: (value) =>
                          value.trim()
                              ? true
                              : `This field is required!`,
                  },
              })}
          />
            <ErrorMessage
                className="text-red-500 text-xs"
                errors={errors}
                name="metaDescription"
                as="p"
            />
        </div>
        
      {/* ------------------------ Table of Content ---------------------- */}
        <div>
          <Label htmlFor="title">Table of Content</Label>
          <p className='text-xs text-gray-400 '>A table of contents, usually headed simply Contents and abbreviated informally as TOC, is a list, usually found on a page before the start of a written work, of its chapter or section titles or brief descriptions with their commencing page numbers.</p>
          <div className='text-sm font-medium mt-3'>Content Body</div>

          {controlledFields.map((field: any, index: number) => {
            return (
              <div className='px-4 mt-3' key={index}>
                <div className=' grid grid-cols-4 gap-4'>
                  <div className=' col-span-3'>
                    <Label htmlFor="menu">{`Heading of Content`}</Label>
                    <input
                      className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                      type="text"
                      id="heading"
                      placeholder={"Enter heading"}
                      {...register(`contents.${index}.heading` as const)}
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      errors={errors}
                      name={`contents.${index}.heading`}
                      as="p"
                    />
                  </div>
                  <div className=' col-span-1'>
                    <Label htmlFor="idContent">ID Content:</Label>
                    <input
                        className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                        type="text"
                        id="idLinkToContent"
                        placeholder={"Enter ID"}
                        {...register(`contents.${index}.idContent` as const)}
                    />
                  </div>
                </div>
                {(get(currentBlog, 'contents', false) || !policy) && <Editor defaultValue={get(currentBlog, `contents.${index}.content`, '')} onChange={(value: any) => setValue(`contents.${index}.content`, value)}/>}
              </div>
            )
          })}

          <div 
            onClick={addMoreContent}
            className=' ml-4 text-xs px-2 py-2 rounded-md border border-gray-400 hover:opacity-75 mt-4 w-fit'>
            Add more content
          </div>
        </div>
      
        <div className='flex justify-end'>
          <button type="submit" onClick={() => null} className=' text-sm text-white px-5 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'>{'Update'}</button>
        </div>
      </form>
      {
        loading && <Spinner />
      }
    </div>
    
  )
}
