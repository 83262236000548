/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import SearchInput from '../../components/SearchInput';
import { useDebouncedState } from '../../hooks/useDebounce';
import { PlusIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Table from '../../components/Table';
import Form from './Form';
import blogsAPI from '../../api/blogs';
import TablePagination from '../../components/Pagination';
import { useNotifier } from 'react-headless-notifier';
import { SuccessNotification } from '../../components/Notification';
import { DangerNotification } from '../../components/Notification';
import Spinner from '../../components/Spinner';

export default function Blogs() {
  const [keyword, setKeyword] = useDebouncedState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const { notify } = useNotifier();
  const [blogs, setBlogs] = useState({
    currentPage: 1,
    data: [],
    totalPages: 1
  })
  
  const [loading, setLoading] = useState(true)
  const [selectedBlog, setSelectedBlog] = useState('')

  const getBlogs = (page = 1) => {
    setLoading(true)
    const body = { page, limit: 10, search: keyword }
    blogsAPI.getBlogs(body).then((res: any) => {
      if (res.data) {
        setBlogs({...blogs, ...res.data})
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getBlogs()
  }, [keyword])

  const handleActiveBlog = (slug:string, isActive: boolean) => {
      setLoading(true)
      blogsAPI.updateBlog({ slug, isActive })
      .then(() => {
        getBlogs()
        notify(
          <SuccessNotification
            message="Changed status success!"
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      })
      .catch(() => {
        notify(
          <DangerNotification
            message="Changed status Fail! Please try it again "
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      }).finally(() => setLoading(false))
  }

  const deleteBlog = (slug:string) => {
    setLoading(true)
    blogsAPI.deleteBlog(slug)
    .then(() => {
      getBlogs()
      notify(
        <SuccessNotification
          message="Deleted blog success!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      );
    })
    .catch(() => 
      notify(
        <DangerNotification
          message="Deleted blog fail! Please try it again "
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )).finally(() => setLoading(false))
  }

  const createBlog = (data: any) => {
    setLoading(true)
    blogsAPI.createBlog(data).then((res) => {
      getBlogs()
      setOpenForm(false)
      notify(
        <SuccessNotification
          message="Created blog success!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      );
    }).catch(() => 
      notify(
        <DangerNotification
          message="Created blog fail! Please try it again "
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        }
      )).finally(() => setLoading(false))
  }

  const updateBlog = (data: any) => {
    setLoading(true)
    blogsAPI.updateBlog(data)
    .then((res) => {
      if (res.data) {
        getBlogs()
        setSelectedBlog('')
        notify(
          <SuccessNotification
            message="Updated blog success!"
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      }
    })
    .catch(() => 
      notify(
        <DangerNotification
          message="Updated blog fail!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )
    ).finally(() => setLoading(false));
  };

  const handlePagination = (nextPage: number) => {
    getBlogs(nextPage)
  };

  return (
    <div className='relative'>
      {
        isOpenForm || selectedBlog ? 
        <Form backFn={setOpenForm} createBlog={createBlog} updateBlog={updateBlog} selectedBlog={selectedBlog} setSelectedBlog={setSelectedBlog}/>
        :
        <div>
          <div className='flex justify-between mb-4'>
            <SearchInput onSubmit={(e: any) => setKeyword(e)} />
            <button onClick={() => setOpenForm(true)} className=' text-sm text-white px-3 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'><PlusIcon className='w-4 h-4 mr-1'/> Add New Blog</button>
          </div>
          <div className='h-[calc(100vh-200px)] w-[calc(100vw-310px)] overflow-scroll'>
            <Table data={blogs.data} handleActive={handleActiveBlog} handleDelete={deleteBlog} setSelected={setSelectedBlog} detail="blogs" />
          </div>
          <TablePagination 
            onClickPagination={handlePagination}
            perPage={10}
            total={+blogs?.totalPages * 10}
            forcePage={+blogs.currentPage - 1}
            
          />
        </div>
      }
     {
      loading && <Spinner />
     }
    </div>
  )
}
