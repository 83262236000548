/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const userAPI = {
  signin: (payload) => {
    console.log("payload: ", payload);
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/signin`,
      data: payload,
    });
  },
  createUser: (payload) => {
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/signup`,
      data: { ...payload },
    });
  },
  getUsers: () => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/users`,
    });
  },

  getProfile: (email) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/users/email=${email}`,
    });
  },

  deleteUser: (email) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/user`,
      data: { email },
    });
  },
};

export default userAPI;
