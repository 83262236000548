/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Label from '../../../components/Label';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import Editor from '../../../components/Editor';
import { AsyncPaginate } from 'react-select-async-paginate';
import blogsAPI from '../../../api/blogs';
import {get} from 'lodash';
import TagsAPI from '../../../api/tags';
import { components }  from 'react-select'
import Spinner from '../../../components/Spinner';
import coursesAPI from '../../../api/courses';
import lessonsAPI from '../../../api/lessons';

interface Props {
  backFn: Function;
  handleCreate: Function
  handleUpdate: Function
  selected: string
  setSelected: Function
}

export default function Form(props: Props) {
  const { backFn, handleCreate, handleUpdate, selected, setSelected } = props
  const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      getValues,
      reset,
  } = useForm({mode: 'onChange'});

  const { fields, append } = useFieldArray({
    control,
    name: "contents"
  });

  const [currentBlog, setCurrentBlog] = useState<any>({banner_mobile: undefined, banner_desktop: undefined, thumb_mobile: undefined, thumb_desktop: undefined, icon_mobile: undefined})
  const [loading, setLoading] = useState(false);

  const watchFieldArray = watch("contents");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  useEffect(() => {
    if(selected) {
      setLoading(true);
      lessonsAPI.getLesson(selected)
      .then((res: any) => { 
        reset({
          ...res.data,
          thumb_desktop: undefined,
          thumb_mobile: undefined,
          banner_mobile: undefined,
          banner_desktop: undefined,
          icon_mobile: undefined
        })
        setCurrentBlog({
          ...res.data,
          thumb_desktop: undefined,
          thumb_mobile: undefined,
          banner_mobile: undefined,
          banner_desktop: undefined,
          icon_mobile: undefined
        })
        
      }).finally(() => setLoading(false))
    }
  }, [])

  const onSubmit = (val: any) => {
    const params = {
      ...val,
      contents: (getValues('contents') || []).map((item: any) => {
        return {
          ...item,
          idContent: item.idContent !== '' ? item.idContent : item.heading.replaceAll(" ", "-") 
        }
      })
    }
    if (!params.thumb_desktop?.[0]) delete params.thumb_desktop
    if (!params.banner_desktop?.[0]) delete params.banner_desktop
    if (!params.thumb_mobile?.[0]) delete params.thumb_mobile
    if (!params.banner_mobile?.[0]) delete params.banner_mobile
    if (!params.icon_mobile?.[0]) delete params.icon_mobile


    if (!params.tags) delete params.tags
    if(selected) {
      handleUpdate(params)
    } else handleCreate(params)
  }

  const addMoreContent = () => {
     append({heading: "", idContent: "", content: ""});
  }

  return (
    <div>
      <div className='flex items-center hover:opacity-75 cursor-pointer '
        onClick={() => {backFn(false); setSelected('')}}
      >
        <ArrowLeftIcon className=' w-3 h-3 mr-1' />
        <div className=' text-sm text-black py-2 '>Back</div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" gap-x-8 gap-y-5 mt-2 bg-white rounded-lg max-h-[84vh] overflow-scroll py-4 px-14"
      >
        <div className=' grid grid-cols-5 gap-5'>
          <div className='col-span-2'>
            <Label htmlFor="banner_desktop">Banner Desktop</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl cursor-pointer flex justify-center items-center relative'>
              <input
                className='w-full h-full'
                hidden
                type="file"
                accept="image/*"
                size={1048576}
                {...register('banner_desktop')}
              />
              <img onError={(event: any) => event.target.style.display = 'none'} onLoad={(event:any) => event.target.style.display = "block"}  className=' absolute object-contain origin-center w-full h-full' src={(watch(`banner_desktop`)?.[0] && URL?.createObjectURL(watch(`banner_desktop`)?.[0])) || `${process.env.REACT_APP_API_URL}/lesson/banner_desktop/${selected}` } />
              <div className='italic'>
                <p className=' text-xs text-gray-600 text-center'>Upload Banner Image </p>
                <p className='text-xs text-gray-400 mt-1'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
              </div>
            </label>
          </div>
          <div className='col-span-2'>
            <Label htmlFor="banner_mobile">Banner Mobile</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl cursor-pointer flex justify-center items-center relative'>
              <input
                className='w-full h-full'
                hidden
                type="file"
                accept="image/*"
                size={1048576}
                {...register('banner_mobile')}
              />
              <img onError={(event: any) => event.target.style.display = 'none'} onLoad={(event:any) => event.target.style.display = "block"}  className=' absolute object-contain origin-center w-full h-full' src={(watch(`banner_mobile`)?.[0] && URL?.createObjectURL(watch(`banner_mobile`)?.[0])) || `${process.env.REACT_APP_API_URL}/lesson/banner_mobile/${selected}` } />
              <div className='italic'>
                <p className=' text-xs text-gray-600 text-center'>Upload Banner Image </p>
                <p className='text-xs text-gray-400 mt-1'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
              </div>
            </label>
          </div>
          <div className='col-span-1'>
            <Label htmlFor="icon_mobile">Icon Mobile</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl cursor-pointer flex justify-center items-center relative'>
              <input
                className='w-full h-full'
                hidden
                type="file"
                accept="image/*"
                size={1048576}
                {...register('icon_mobile')}
              />
              <img onError={(event: any) => event.target.style.display = 'none'} onLoad={(event:any) => event.target.style.display = "block"}  className=' absolute object-contain origin-center w-full h-full' src={(watch(`icon_mobile`)?.[0] && URL?.createObjectURL(watch(`icon_mobile`)?.[0])) || `${process.env.REACT_APP_API_URL}/lesson/icon_mobile/${selected}` } />
              <div className='italic'>
                <p className=' text-xs text-gray-600 text-center'>Upload Banner Image </p>
                <p className='text-xs text-gray-400 mt-1 text-center'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
              </div>
            </label>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-5 mt-3'>
          <div className=' col-span-1'>
            <div >
              <Label htmlFor="title">Title</Label>
              <input
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                  type="text"
                  id="title"
                  placeholder={"Enter Title"}
                  {...register('title', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="title"
                    as="p"
                />
            </div>
          
            <div className='mt-2'>
              <Label htmlFor="keywords">Keywords</Label>
              <textarea
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none h-64"
                  id="keywords"
                  placeholder={"Enter Meta Kewords"}
                  maxLength={500}
                  {...register('keywords', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="keywords"
                    as="p"
                />
            </div>   
          </div>
          <div className='mt-2 col-span-1'>
            <Label htmlFor="thumb_mobile">Thumbnail Mobile</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl flex justify-center items-center flex-col cursor-pointer relative'>
              <input
                  className='w-full h-full'
                  hidden
                  type="file"
                  accept="image/*"
                  size={1048576}
                  {...register('thumb_mobile')}
                />
              <img onError={(event: any) => event.target.style.display = 'none'} onLoad={(event:any) => event.target.style.display = "block"}  className=' absolute object-contain origin-center w-full h-full' src={(watch(`thumb_mobile`)?.[0] && URL?.createObjectURL(watch(`thumb_mobile`)?.[0])) || `${process.env.REACT_APP_API_URL}/lesson/thumb_mobile/${selected}` } />
              <div className='italic'>
                <p className=' text-xs text-gray-600 text-center'>Upload Thumbnail Image </p>
                <p className='text-xs text-gray-400 mt-1 text-center'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
              </div>
            </label>
          </div>
          <div className='mt-2 col-span-1'>
            <Label htmlFor="thumb_desktop">Thumbnail Desktop</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl flex justify-center items-center flex-col cursor-pointer relative'>
              <input
                  className='w-full h-full'
                  hidden
                  type="file"
                  accept="image/*"
                  size={1048576}
                  {...register('thumb_desktop')}
                />
                <img onError={(event: any) => event.target.style.display = 'none'} onLoad={(event:any) => event.target.style.display = "block"} className=' absolute object-contain origin-center w-full h-full' src={(watch(`thumb_desktop`)?.[0] && URL?.createObjectURL(watch(`thumb_desktop`)?.[0])) || `${process.env.REACT_APP_API_URL}/lesson/thumb_desktop/${selected}` } />
                <div className='italic'>
                  <p className=' text-xs text-gray-600 text-center'>Upload Thumbnail Image </p>
                  <p className='text-xs text-gray-400 mt-1 text-center'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                  <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
                </div>
            </label>
          </div>
        </div>
        
        <div>
          <Label htmlFor="metaDescription">Meta Description</Label>
          <textarea
              className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
              id="metaDescription"
              placeholder={"Enter Meta Description"}
              maxLength={500}
              {...register('metaDescription', {
                  required: {
                      value: true,
                      message: `This field is required!`
                  },
                  validate: {
                      shouldNotContainSpace: (value) =>
                          value.trim()
                              ? true
                              : `This field is required!`,
                  },
              })}
          />
            <ErrorMessage
                className="text-red-500 text-xs"
                errors={errors}
                name="metaDescription"
                as="p"
            />
        </div>
        
      {/* ------------------------ Table of Content ---------------------- */}
        <div>
          <Label htmlFor="title">Table of Content</Label>
          <p className='text-xs text-gray-400 '>A table of contents, usually headed simply Contents and abbreviated informally as TOC, is a list, usually found on a page before the start of a written work, of its chapter or section titles or brief descriptions with their commencing page numbers.</p>
          <div className='text-sm font-medium mt-3'>Content Body</div>

          {controlledFields.map((field: any, index: number) => {
            return (
              <div className='px-4 mt-3' key={index}>
                <div className=' grid grid-cols-4 gap-4'>
                  <div className=' col-span-3'>
                    <Label htmlFor="menu">{`Heading of Content ${index + 1}`}</Label>
                    <input
                      className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                      type="text"
                      id="heading"
                      placeholder={"Enter heading"}
                      {...register(`contents.${index}.heading` as const)}
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs"
                      errors={errors}
                      name={`contents.${index}.heading`}
                      as="p"
                    />
                  </div>
                  <div className=' col-span-1'>
                    <Label htmlFor="idContent">ID Content:</Label>
                    <input
                        className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                        type="text"
                        id="idLinkToContent"
                        placeholder={"Enter ID"}
                        {...register(`contents.${index}.idContent` as const)}
                    />
                  </div>
                </div>
                {(get(currentBlog, 'contents', false) || !selected) && <Editor defaultValue={get(currentBlog, `contents.${index}.content`, '')} onChange={(value: any) => setValue(`contents.${index}.content`, value)}/>}
              </div>
            )
          })}

          <div 
            onClick={addMoreContent}
            className=' ml-4 text-xs px-2 py-2 rounded-md border border-gray-400 hover:opacity-75 mt-4 w-fit'>
            Add more content
          </div>
        </div>
      
        <div className='flex justify-end'>
          <button type="submit" onClick={() => null} className=' text-sm text-white px-5 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'>{selected? 'Save' :  'Submit'}</button>
        </div>
      </form>
      {
        loading && <Spinner />
      }
    </div>
    
  )
}
