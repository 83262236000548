import React, { KeyboardEventHandler, memo } from "react";

import CreatableSelect from "react-select/creatable";

interface IProps {
  placeholder?: string;
  onChange: (value: Option[]) => void;
  defaultValue?: Option[];
}

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

function InputMulti({ placeholder, defaultValue, onChange }: IProps) {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<readonly Option[]>(() => {
    return defaultValue || [];
  });

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => {
          const newState = [...prev, createOption(inputValue)];
          onChange(newState);
          return newState;
        });
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      classNames={{
        control: () => {
          return "!border !min-h-[2.75rem] !border-[#dcdcdc] text-[14px] w-full text-base !rounded-lg !outline-none !focus:border-[#97B4FF]";
        },
      }}
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
    />
  );
}

export default memo(InputMulti);
