/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const policyAPI = {
  getDoc: (slug) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/doc/${slug}`,
    });
  },
  updateDoc: (blog) => {
    const bodyFormData = new FormData();
    blog.title && bodyFormData.append("title", blog.title);
    blog.keywords && bodyFormData.append("keyworks", blog.keywords);
    blog.contents &&
      bodyFormData.append("contents", JSON.stringify(blog.contents));
    // blog.photo && bodyFormData.append("photo", blog.photo?.[0]);
    blog.metaDescription &&
      bodyFormData.append("metaDescription", blog.metaDescription);
    bodyFormData.append("isActive", blog.isActive);
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/doc/${blog.slug}`,
      data: bodyFormData,
    });
  },
};

export default policyAPI;
