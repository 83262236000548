/* eslint-disable no-undef */
import apiService from "./ApiService";

export type FormValues = {
  title: string;
  slug: string;
  questions: {
    id?: string;
    question: string;
    answerOptions: string[];
    correctAnswerIndex: number;
    rewards: {
      amount: number;
      assetType: string;
    }[];
    lessonId?: string;
  }[];
};

export type UpdatePayload = {
  id?: string;
  title: string;
  slug: string;
  questions: {
    id?: string;
    lessonId?: string;
    question: string;
    answerOptions: string[];
    correctAnswerIndex: number;
    rewards: {
      amount: number;
      assetType: string;
    }[];
  }[];
};

const REACT_APP_API_URL = process.env.REACT_APP_API_ADMIN_INSIGHT_URL;
// const REACT_APP_API_URL = "http://localhost:3300/api";

const adminInsightAPI = {
  getLesson: (id: string) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/learning/lesson/${id}`,
    });
  },
  getLessons: (params: any) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/learning/lessons`,
      params,
    });
  },
  createLesson: (payload: FormValues) => {
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/learning/lesson`,
      data: payload,
    });
  },
  updateLesson: (payload: UpdatePayload) => {
    const { id, ...others } = payload;
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/learning/lesson/${id}`,
      data: others,
    });
  },
  deleteLesson: (id: string) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/learning/lesson/${id}`,
    });
  },
  deleteQuestion: (id: string) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/learning/question/${id}`,
    });
  },
};

export default adminInsightAPI;
