/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const blogsAPI = {
  getBlogs: (payload) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/blogs?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
    });
  },
  createBlog: (blog) => {
    console.log("blog: ", blog);
    const bodyFormData = new FormData();
    bodyFormData.append("title", blog.title);
    bodyFormData.append("tags", blog.tags);
    bodyFormData.append("contents", JSON.stringify(blog.contents));
    bodyFormData.append("photo", blog.photo?.[0]);
    bodyFormData.append("metaDescription", blog.metaDescription);
    bodyFormData.append("keywords", blog.keywords);
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/user/blog`,
      data: bodyFormData,
    });
  },
  getBlog: (slug) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/blog/${slug}`,
    });
  },
  updateBlog: (blog) => {
    const bodyFormData = new FormData();
    blog.title && bodyFormData.append("title", blog.title);
    blog.tags && bodyFormData.append("tags", blog.tags);
    blog.keywords && bodyFormData.append("keywords", blog.keywords);
    blog.contents &&
      bodyFormData.append("contents", JSON.stringify(blog.contents));
    blog.photo && bodyFormData.append("photo", blog.photo?.[0]);
    blog.metaDescription &&
      bodyFormData.append("metaDescription", blog.metaDescription);
    bodyFormData.append("isActive", blog.isActive);
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/blog/${blog.slug}`,
      data: bodyFormData,
    });
  },

  deleteBlog: (slug) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/blog/${slug}`,
    });
  },
};

export default blogsAPI;
