import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LegacyRef,
} from "react";

const inputClass =
  "px-4 py-2.5 border h-11 border-[#dcdcdc] text-[14px] w-full text-base rounded-lg outline-none focus:border-[#97B4FF]";

export type BaseInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const BaseInput = (
  props: BaseInputProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  return <input ref={ref} className={`${inputClass}`} {...props} />;
};

export default React.forwardRef(BaseInput);
