/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import SearchInput from "../../components/SearchInput";
import { useDebouncedState } from "../../hooks/useDebounce";
import { PlusIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useNotifier } from "react-headless-notifier";

import Form from "./components/FormQuestion";
import TablePagination from "../../components/Pagination";
import { SuccessNotification } from "../../components/Notification";
import { DangerNotification } from "../../components/Notification";
import Spinner from "../../components/Spinner";
import adminInsightAPI from "../../api/admin-insight";
import DynamicTable from "../../components/DynamicTable";

export default function LessonMobile() {
  const [keyword, setKeyword] = useDebouncedState("");
  const [isOpenForm, setOpenForm] = useState(false);
  const { notify } = useNotifier();
  const [data, setData] = useState({
    currentPage: 1,
    data: [],
    totalPages: 1,
  });

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("");

  const getData = useCallback(
    (page = 1) => {
      setLoading(true);
      const body = { limit: 10, search: keyword, page };
      adminInsightAPI
        .getLessons(body)
        .then((res: any) => {
          if (res.data) {
            setData({
              ...data,
              data: res.data.data,
              currentPage: page,
              totalPages: Math.ceil(res.data.paginationInfo.total / body.limit),
            });
          }
        })
        .finally(() => setLoading(false));
    },
    [keyword]
  );

  useEffect(() => {
    getData();
  }, [keyword]);

  const handleDelete = (id: string) => {
    setLoading(true);
    adminInsightAPI
      .deleteLesson(id)
      .then(() => {
        getData();
        notify(<SuccessNotification message="Deleted lesson success!" />, {
          position: "bottomRight",
          duration: 3000,
        });
      })
      .catch(() =>
        notify(
          <DangerNotification message="Deleted lesson fail! Please try it again " />,
          {
            position: "bottomRight",
            duration: 3000,
          }
        )
      )
      .finally(() => setLoading(false));
  };

  const handlePagination = (nextPage: number) => {
    getData(nextPage);
  };

  return (
    <div className="relative">
      {(isOpenForm || selected) && (
        <div
          className="flex items-center hover:opacity-75 cursor-pointer "
          onClick={() => {
            setOpenForm(false);
            setSelected("");
          }}
        >
          <ArrowLeftIcon className=" w-3 h-3 mr-1" />
          <div className=" text-sm text-black py-2 ">Back</div>
        </div>
      )}
      {isOpenForm || selected ? (
        <Form id={selected} />
      ) : (
        <div>
          <div className="flex justify-between mb-4">
            <SearchInput onSubmit={(e: any) => setKeyword(e)} />
            <button
              onClick={() => setOpenForm(true)}
              className=" text-sm text-white px-3 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center"
            >
              <PlusIcon className="w-4 h-4 mr-1" /> Add New Lesson
            </button>
          </div>
          <div className="h-[calc(100vh-200px)] w-[calc(100vw-310px)] overflow-scroll">
            <DynamicTable
              haveAction
              data={data.data}
              headers={[
                { label: "Title", name: "title" },
                { label: "Slug", name: "slug" },
              ]}
              handleDelete={handleDelete}
              setSelected={setSelected}
            />
          </div>
          <TablePagination
            onClickPagination={handlePagination}
            perPage={10}
            total={+data?.totalPages * 10}
            forcePage={+data.currentPage - 1}
          />
        </div>
      )}
      {loading && <Spinner />}
    </div>
  );
}
