/* eslint-disable no-undef */
import axios from "axios";
// import notification from 'src/components/Alert'

const apiService = axios.create();
// Request interceptor for API calls
apiService.interceptors.request.use(
  async (config) => {
    const token = window.localStorage.getItem("token");
    config.baseURL = process.env.REACT_APP_URL_API;
    config.headers = {
      Authorization: "Bearer " + token,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // notification('error', {
    //   message: 'Error',
    //   description: error.message,
    // })
    if (error?.response?.status === 401) {
      window.location.replace("/login");
    }
  }
);

export const serialize = function (obj) {
  var str = [];
  for (var i in obj)
    if (obj[i] !== undefined) {
      str.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
    }
  return str.join("&");
};

export default apiService;
