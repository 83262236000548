import { ArrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LineTo  from 'react-lineto';
import courseAPI from '../../api/courses';
import Spinner from '../../components/Spinner';
import './style.css';


const  ViewBlog = () =>  {
  const [data, setData] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    courseAPI.getCourse(params.id).then((res: any) => {
      if (res.data) {
        setData(res.data);
        
      }
    }).finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getData();
  },[]);

  return (
    <div className='content-viewer w-full relative h-screen'>
      <div className='flex items-center hover:opacity-75 cursor-pointer'
          onClick={() => navigate('/courses')}
        >
          <ArrowLeftIcon className=' w-3 h-3 mr-1' />
          <div className=' text-sm text-black py-2 '>Back</div>
        </div>
      {
        loading ? 
          <Spinner />
          :
          <div className=''>
            <div className=' rounded-md bg-white p-6'>
              <h2 className=' text-lg font-semibold '>Current Course</h2>
              <div className='flex w-full justify-around items-center p-20 h-full'>
                <div  className={` min-w-[260px] rounded-md overflow-hidden mt-6 shadow-md h-fit course-${data?._id}`}>
                    <img className=' w-full h-52 object-cover bg-center bg-black backdrop-blur-sm !m-0' src={process.env.REACT_APP_API_URL + "/course/photo/" + data?.slug} />
                    <div className=' p-2 text-black'>
                      <div className=' truncate font-medium text-base' >{data?.title}</div>
                      <div className=' truncate text-xs mt-1' >{data?.metaDescription}</div>
                    </div>
                </div>
                <ArrowNarrowRightIcon  className='w-10 h-10'/>
                <div className='flex flex-col'>
                  {
                    data?.lessons.map((lesson:any) => {
                      return (
                        <div key={lesson?._id} className={`m-5 lesson-${lesson?._id}`}>
                          <LineTo zIndex={10} from={`course-${data?._id}`} to={`lesson-${lesson?._id}`}/>
                          
                          <Link to={`/lessons/${lesson?.slug}`} className={`text-sm rounded-md py-4 px-3 w-80 truncate  cursor-pointer shadow-md `}>
                          {
                            lesson.title
                          }
                        </Link>
                        </div>
                        
                      );
                    })
                  }
                </div>
              </div>
            </div>
           
          </div>
      }
    </div>
    
  );
};

export default ViewBlog;