/* eslint-disable react/react-in-jsx-scope */

import moment from "moment";
import MenuContextOptions from "./ContextMenu";

const Table = ({data, handleActive, handleDelete, setSelected, detail}: any) => {
  return (
      <div>
        <table className="shadow-md rounded-md w-full">
          <thead className="bg-slate-50 border-b border-slate-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md max-w-[320px]">
                 Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md max-w-[380px]">
                Description
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                Posted By
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                Updated At
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                Status
              </th>
              <th className="px-6 py-3 text-sm font-medium text-slate-900 rounded-tr-md text-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((el: any) => {
                return (
                  <tr key={el._id} className="odd:bg-white even:bg-slate-50">
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[320px]">
                      {el.title}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[380px]">
                      {el.metaDescription}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis capitalize">
                      {el.postedBy.name}
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900">
                      {moment(el.updatedAt).format('lll')}
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900">
                      {el.isActive ? 
                        <div className=" px-3 py-2 rounded-lg text-center bg-green-300 text-green-700 font-medium w-fit">Active</div>
                        :
                        <div className=" px-3 py-2 rounded-lg text-center bg-red-300 text-red-700 font-medium w-fit">Inactive</div>
                      }
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900 text-right">
                      <MenuContextOptions 
                        id={el?.slug} 
                        handleActive={handleActive} 
                        isActive={el.isActive} 
                        handleDelete={handleDelete}
                        setSelected={setSelected}
                        detail={detail}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
  );
};

export default Table;
