/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const TagsAPI = {
  getTags: (payload) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/tags?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
    });
  },
  createTag: (tag) => {
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/tag`,
      data: { name: tag },
    });
  },
  updateTag: (id, name) => {
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/tag/${id}`,
      data: { name },
    });
  },
  deleteTag: (id) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/tag/${id}`,
    });
  },
};

export default TagsAPI;
