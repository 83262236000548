// import { ArrowLeftIcon } from '@heroicons/react/outline';
// import moment from 'moment';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import './style.css';

export default function ViewBlog({ policy }: any) {
  // const navigate = useNavigate();

  return (
    <div className='content-viewer w-full relative'>
      {/* <div className='flex items-center hover:opacity-75 cursor-pointer'
          onClick={() => navigate('/blogs')}
        >
          <ArrowLeftIcon className=' w-3 h-3 mr-1' />
          <div className=' text-sm text-black py-2 '>Back</div>
        </div> */}

          <div className='bg-white  rounded-lg items-center justify-center p-10  grid grid-cols-6 '>
            <div className='py-8 text-sm max-h-[74vh] overflow-scroll col-span-4'>
              <h1 className='text-2xl text-center max-w-4xl m-auto font-semibold mb-8 mt-8'>{policy?.title}</h1>

              {policy?.photo && <img src={`${process.env.REACT_APP_API_URL}/blog/photo/${policy?.slug}`} />}

              {/* <div className='text-xs text-gray-500 my-4'>
                <div>Author: <span className=' capitalize'>{policy?.postedBy?.name}</span></div>
                <div>{moment(policy?.postedBy?.updatedAt).format('lll')}</div>
              </div> */}
              
              {
                policy?.contents?.map((item: any) => {
                  return (
                    <div key={item._id}>
                      {item.heading && <h2 className=' text-base font-medium py-3' id={item.idContent}>{item?.heading}</h2>}
                      <p className='px-4'
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                    </div>
                  );
                })
              }
           
            </div>
            { policy?.contents?.find((item:any) => item.heading !== '') &&
                <div className='p-4 border rounded-ld border-dotted mb-8 w-full col-span-2'>
                  {/* <h2 className=' text-sm font-medium'>Table of Content</h2> */}
                  {
                    policy?.contents?.map((item: any) => {
                      if (!item.heading) return;
                      return (
                        <h3 key={item._id} className=' text-sm font-normal italic  text-gray-500 my-2'><a href={`#${item.idContent}`}> {item.heading}</a></h3>
                      );
                    })
                  }
                </div>
              }
          </div>
    </div>
    
  );
}
