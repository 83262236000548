/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react';
import Label from '../../../components/Label';
import { useQuill } from 'react-quilljs';
interface Props {
  onChange: Function;
  defaultValue: any
}

const modules =  {
  'syntax': true,
  'toolbar': [
    [{ 'font': [] }, { 'size': [] }],
    [ 'bold', 'italic', 'underline', 'strike' ],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'super' }, { 'script': 'sub' }],
    [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
    [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
    [ 'direction', { 'align': [] }],
    [ 'link', 'image', 'video', 'formula' ],
    [ 'clean' ]
]
};

export default function Editor(props: Props) {
  const { quill , quillRef } = useQuill();

  useEffect(() => {
    props.onChange(quillRef?.current?.firstChild?.innerHTML);
  }, [quillRef?.current?.firstChild?.innerHTML]);

  useEffect(() => {
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(props.defaultValue);
      }
  }, [quill]);

  return (
    <div className='mt-1 rounded-md'>
      <Label htmlFor="idContent">Content</Label>
      <div className='w-full mt-1 bg-white'>
        <div 
          ref={quillRef} 
        />
      </div>

      <div className='flex justify-end'>
        <div 
          onClick={() => props.onChange(quillRef?.current?.firstChild?.innerHTML)}
          className='ml-4 text-xs px-2 py-2 rounded-md border border-gray-400 hover:opacity-75 mt-4 w-fit cursor-pointer'>
            {props.defaultValue ? 'Update This Content' : 'Add This Content' }
        </div>
      </div>
    </div>
  );
}
