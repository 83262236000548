/* eslint-disable react/react-in-jsx-scope */

import MenuContextOptions from "./ContextMenu";

interface IProps {
  haveAction?: boolean;
  headers: {
    label: string;
    name: string;
  }[];
  data: any;
  handleActive?: any;
  handleDelete?: any;
  setSelected?: any;
  detail?: string;
}

const DynamicTable = ({
  data,
  handleActive,
  handleDelete,
  setSelected,
  detail,
  headers,
  haveAction,
}: IProps) => {
  return (
    <div>
      <table className="shadow-md rounded-md w-full">
        <thead className="bg-slate-50 border-b border-slate-200">
          <tr>
            {headers.map((header) => (
              <th
                key={header.name}
                className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md max-w-[320px]"
              >
                {header.label}
              </th>
            ))}
            {haveAction && <th></th>}
          </tr>
        </thead>
        <tbody>
          {data.map((el: any) => {
            return (
              <tr
                key={el._id || el.id}
                className="odd:bg-white even:bg-slate-50"
              >
                {headers.map((header) => (
                  <td
                    key={header.name}
                    className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[320px]"
                  >
                    {el[header.name]}
                  </td>
                ))}
                {/* <td className="px-6 py-4 text-sm text-slate-900">
                  {el.isActive ? (
                    <div className=" px-3 py-2 rounded-lg text-center bg-green-300 text-green-700 font-medium w-fit">
                      Active
                    </div>
                  ) : (
                    <div className=" px-3 py-2 rounded-lg text-center bg-red-300 text-red-700 font-medium w-fit">
                      Inactive
                    </div>
                  )}
                </td> */}
                <td className="px-6 py-4 text-sm text-slate-900 text-right">
                  <MenuContextOptions
                    id={el?.id}
                    handleActive={handleActive}
                    isActive={el.isActive}
                    handleDelete={handleDelete}
                    setSelected={setSelected}
                    detail={detail}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
