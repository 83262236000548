import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

interface SearchInputProps {
    onSubmit: (e: string) => void;
    defaultValue?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
    onSubmit,
}) => {


    return (
        <div className="flex-1 flex items-center justify-center lg:justify-start w-[300px] ">
            <div className="">
                <label htmlFor="search" className="sr-only">
                    {"Search"}
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
                        <SearchIcon
                            className=" h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        id="search"
                        name="search"
                        autoComplete="off"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500  sm:text-sm focus:outline-none"
                        placeholder={"Search"}
                        type="search"
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                onSubmit(e.target.value);
                            }
                        }}
                        onChange={(e) => {
                            onSubmit(e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchInput;
