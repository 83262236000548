/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/react-in-jsx-scope */

import MenuContextOptions from "./ContextMenu";
import moment from 'moment';

interface Props {
  data: any
  deleteTag: Function
  setSelectedTag: Function
}

const Table = (props: Props) => {
  return (
      <div>
        <table className="shadow-md rounded-md table-fixed w-full">
          <thead className="bg-slate-50 border-b border-slate-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md">
                Tag Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                Updated At
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tr-md text-right">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              props.data.map((tag: any) => {
                return (
                  <tr key={tag?._id} className="odd:bg-white even:bg-slate-50">
                    <td className="px-6 py-4 text-xs font-medium text-slate-900">
                      <div className=" border-dotted px-3 py-2 rounded-xl w-fit border">{tag?.name}</div>
                    </td>
                    <td className="px-6 py-4 text-xs text-slate-900">
                      {moment(tag?.updatedAt).format('lll')}
                    </td>
                    <td className="px-6 py-4 text-xs text-slate-900 text-right">
                      <MenuContextOptions tag={tag} deleteTag={props?.deleteTag} setSelectedTag={props.setSelectedTag}/>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
  );
};

export default Table;
