import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "quill/dist/quill.snow.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Dashboard from "./dashboard";
import Signin from "./pages/Signin";
import NotFound from "./notfound";
import Tags from "./pages/Tags";
import Blogs from "./pages/Blogs";
import Policy from "./pages/Policy";

import ViewBlog from "./pages/Blogs/ViewBlog";
import { NotifierContextProvider } from "react-headless-notifier";
import TermOfService from "./pages/TermOfService";
import Courses from "./pages/Courses";
import Lessons from "./pages/Lessons";
import LessonMobile from "./pages/LessonMobile/LessonMobile";
import ViewLesson from "./pages/Lessons/ViewLesson";
import ViewCourse from "./pages/Courses/ViewCourse";

ReactDOM.render(
  <NotifierContextProvider
    // All props are optional, those are the values by default
    config={{
      max: null, // Max number of notiication simultaneously, `null` will result in no maximum
      duration: 5000, // Duration by notification in milliseconds
      position: "bottomRight", // Default position for all the notification if it's not specify when using `notify()`, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
    }}
  >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/blogs/:id" element={<ViewBlog />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/lesson-mobile" element={<LessonMobile />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/lessons/:id" element={<ViewLesson />} />
          <Route path="/courses/:id" element={<ViewCourse />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/term-of-services" element={<TermOfService />} />
        </Route>
        <Route path="/login" element={<Signin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </NotifierContextProvider>,
  document.getElementById("root")
);
