/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const coursesAPI = {
  getCourses: (payload) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/courses?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
    });
  },
  createCourse: (course) => {
    console.log("course: ", course);
    const bodyFormData = new FormData();
    bodyFormData.append("title", course.title);
    bodyFormData.append("lessons", course?.lessons);
    bodyFormData.append("photo", course.photo?.[0]);
    bodyFormData.append("metaDescription", course.metaDescription);
    bodyFormData.append("keywords", course.keywords);
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/course`,
      data: bodyFormData,
    });
  },
  getCourse: (slug) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/course/${slug}`,
    });
  },
  updateCourse: (course) => {
    const bodyFormData = new FormData();
    course.title && bodyFormData.append("title", course.title);
    course.lessons && bodyFormData.append("lessons", course.lessons);
    course.keywords && bodyFormData.append("keywords", course.keywords);
    course.photo && bodyFormData.append("photo", course.photo?.[0]);
    course.metaDescription &&
      bodyFormData.append("metaDescription", course.metaDescription);
    bodyFormData.append("isActive", course.isActive);
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/course/${course.slug}`,
      data: bodyFormData,
    });
  },

  deleteCourse: (slug) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/course/${slug}`,
    });
  },
};

export default coursesAPI;
