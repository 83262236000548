import { ArrowLeftIcon } from '@heroicons/react/outline';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import blogsAPI from '../../api/blogs';
import Spinner from './../../components/Spinner';
import './style.css';

export default function ViewBlog() {
  const [blog, setBlog] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    blogsAPI.getBlog(params.id).then((res: any) => {
      if (res.data) {
        setBlog(res.data);
        setLoading(false);
      }
    });
  },[]);
  return (
    <div className='content-viewer w-full relative'>
      <div className='flex items-center hover:opacity-75 cursor-pointer'
          onClick={() => navigate('/blogs')}
        >
          <ArrowLeftIcon className=' w-3 h-3 mr-1' />
          <div className=' text-sm text-black py-2 '>Back</div>
        </div>
      {
        loading ? 
          <Spinner />
          :
          <div className='bg-white  rounded-lg flex flex-col items-center justify-center p-10'>
            <div className='py-8 text-sm max-w-4xl max-h-[74vh] overflow-scroll'>
              <h1 className='text-2xl text-center max-w-4xl m-auto font-semibold mb-8 mt-8'>{blog?.title}</h1>

              {/* {blog?.photo && <img src={`${process.env.REACT_APP_API_URL}/blog/photo/${blog?.slug}`} />} */}

              <div className='text-xs text-gray-500 my-4'>
                <div>Author: <span className=' capitalize'>{blog?.postedBy?.name}</span></div>
                <div>{moment(blog?.postedBy?.updatedAt).format('lll')}</div>
              </div>
              { blog?.contents.find((item:any) => item.heading !== '') &&
                <div className='p-4 border rounded-ld border-dotted w-fit mb-8 min-w-[300px]'>
                  <h2 className=' text-sm font-medium'>Table of Content</h2>
                  {
                    blog?.contents?.map((item: any) => {
                      if (!item.heading) return;
                      return (
                        <h3 key={item._id} className=' text-sm font-normal italic  text-gray-500 my-2' id={item.idContent}><a href={`#${item.idContent}`}> {item.heading}</a></h3>
                      );
                    })
                  }
                </div>
              }
              {
                blog?.contents?.map((item: any) => {
                  return (
                    <div key={item._id}>
                      {item.heading && <h2 className=' text-base font-medium py-3' id={item.idContent}>{item?.heading}</h2>}
                      <p className='px-4'
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                    </div>
                  );
                })
              }
              {blog?.tags.length > 0 && <div className=' flex justify-start w-full items-center mt-4 flex-wrap'>
                <div className='font-medium text-sm'>Tags:</div>
                {
                  blog?.tags.map((tag: any) => {
                    return (
                      <div key={tag?._id} className='w-fit px-4 py-2 rounded-lg border-2 border-dotted text-gray-500 font-medium text-sm m-2 italic'>
                        {tag?.name}
                      </div>
                    );
                  })
                }
              </div>}
            </div>
          </div>
      }
    </div>
    
  );
}
