import React, { ReactChild } from 'react';

interface Props {
  htmlFor: string;
  children: ReactChild
}

export default function Label({htmlFor, children} : Props) {
  return (
    <label
      htmlFor={htmlFor}
      className="text-xs font-medium"
    >
        {children}
  </label>
  );
}
