/* eslint-disable no-undef */
import apiService from "./ApiService";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const lessonsAPI = {
  getLessons: (payload) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/lessons?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
    });
  },
  createLesson: (lesson) => {
    const bodyFormData = new FormData();
    bodyFormData.append("title", lesson.title);
    bodyFormData.append("contents", JSON.stringify(lesson.contents));
    bodyFormData.append("banner_desktop", lesson.banner_desktop?.[0]);
    bodyFormData.append("banner_mobile", lesson.banner_mobile?.[0]);
    bodyFormData.append("thumb_mobile", lesson.thumb_mobile?.[0]);
    bodyFormData.append("thumb_desktop", lesson.thumb_desktop?.[0]);
    bodyFormData.append("icon_mobile", lesson.icon_mobile?.[0]);

    bodyFormData.append("metaDescription", lesson.metaDescription);
    bodyFormData.append("keywords", lesson.keywords);
    return apiService({
      method: "POST",
      url: `${REACT_APP_API_URL}/lesson`,
      data: bodyFormData,
    });
  },
  getLesson: (slug) => {
    return apiService({
      method: "GET",
      url: `${REACT_APP_API_URL}/lesson/${slug}`,
    });
  },
  updateLesson: (lesson) => {
    const bodyFormData = new FormData();
    lesson.title && bodyFormData.append("title", lesson.title);
    lesson.keywords && bodyFormData.append("keywords", lesson.keywords);
    lesson.contents &&
      bodyFormData.append("contents", JSON.stringify(lesson.contents));
    lesson.banner_mobile && bodyFormData.append("banner_mobile", lesson.banner_mobile?.[0]);
    lesson.banner_desktop && bodyFormData.append("banner_desktop", lesson.banner_desktop?.[0]);
    lesson.thumb_mobile && bodyFormData.append("thumb_mobile", lesson.thumb_mobile?.[0]);
    lesson.thumb_desktop && bodyFormData.append("thumb_desktop", lesson.thumb_desktop?.[0]);
    lesson.icon_mobile && bodyFormData.append("icon_mobile", lesson.icon_mobile?.[0]);

    lesson.metaDescription &&
      bodyFormData.append("metaDescription", lesson.metaDescription);
    bodyFormData.append("isActive", lesson.isActive);
    return apiService({
      method: "PUT",
      url: `${REACT_APP_API_URL}/lesson/${lesson.slug}`,
      data: bodyFormData,
    });
  },

  deleteLesson: (slug) => {
    return apiService({
      method: "DELETE",
      url: `${REACT_APP_API_URL}/lesson/${slug}`,
    });
  },
};

export default lessonsAPI;
