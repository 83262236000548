/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Label from '../../../components/Label';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import Editor from '../../../components/Editor';
import { AsyncPaginate } from 'react-select-async-paginate';
import coursesAPI from '../../../api/courses';
import {get} from 'lodash';
import TagsAPI from '../../../api/tags';
import { components }  from 'react-select'
import Spinner from '../../../components/Spinner';
import lessonsAPI from '../../../api/lessons';

interface Props {
  backFn: Function;
  handleCreate: Function
  handleUpdate: Function
  selected: string
  setSelected: Function
}

export default function Form(props: Props) {
  const { backFn, handleCreate, handleUpdate, selected, setSelected } = props
  const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      getValues,
      reset,
  } = useForm({mode: 'onChange'});

  const [current, setCurrent] = useState<any>({photo: undefined})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(selected) {
      setLoading(true);
      coursesAPI.getCourse(selected)
      .then((res: any) => {
        console.log('res: ', res)
        reset({
          ...res.data,
          lessons: res.data?.lessons.map((lessons: any) => ({label: lessons?.title, value: lessons?._id})) || []
        })
        setCurrent({
          ...res.data,
        })
        
      }).finally(() => setLoading(false))
    }
  }, [])

  
  const handleLoadLessons = async (inputValue: any, options: any,  additional: any) => {
    const res : any = await lessonsAPI.getLessons({ page: additional.page || 1, limit: 10, search: inputValue })
    const {data, totalPages} = res.data;
    const newOptionsTags: any = [];

    data && data.forEach((el: any) => {
      newOptionsTags.push({
        id: el._id,
        label: el.title,
        value: el._id,
      });
    });
    const result = {
      options: newOptionsTags,
      hasMore: (additional.page || 1) < totalPages,
      additional: {
          page: +additional.page + 1,
      },
    };

    return result;
};

  const onSubmit = (val: any) => {
    const params = {
      ...val,
      lessons: (getValues("lessons") || current.lessons || []).map((lesson: any) => lesson?.id || lesson._id || lesson.value) || []
    }
    if (!params.photo?.[0]) delete params.photo
    if (!params.tags) delete params.tags
    if(selected) {
      handleUpdate(params)
    } else handleCreate(params)
  }

  return (
    <div>
      <div className='flex items-center hover:opacity-75 cursor-pointer '
        onClick={() => {backFn(false); setSelected('')}}
      >
        <ArrowLeftIcon className=' w-3 h-3 mr-1' />
        <div className=' text-sm text-black py-2 '>Back</div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" gap-x-8 gap-y-5 mt-2 bg-white rounded-lg max-h-[84vh] overflow-scroll py-4 px-14"
      >
        <div className='grid grid-cols-2 gap-5'>
          <div className=' col-span-1'>
            <div >
              <Label htmlFor="title">Title</Label>
              <input
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
                  type="text"
                  id="title"
                  placeholder={"Enter Title"}
                  {...register('title', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="title"
                    as="p"
                />
            </div>
            <div className='mt-2'>
              <Label htmlFor="lessons">Lessons</Label>
              <Controller
                  control={control}
                  name="lessons"
                  render={() => {
                      return (
                          <AsyncPaginate
                              loadOptions={handleLoadLessons}
                              additional={{
                                  page: 1,
                              }}
                              debounceTimeout={1000}
                              isMulti
                              isClearable
                              placeholder="Search lesson"
                              value={getValues('lessons')}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              menuPlacement="auto"
                              className=' focus:outline-none text-xs mt-1'
                              onChange={(newValue: any, ) => {
                                setValue(`lessons`, newValue)
                            }}
                            components={{
                              Option: ({ children, ...props }) => {
                                  // console.log('props: ', props)
                                  // FIX LAG
                                  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                                  const newProps = Object.assign(props, { innerProps: rest });
                                  return (
                                      <components.Option {...newProps} >
                                          <div className='my-2'>
                                             <span className=' w-fit px-4 py-2 rounded-full border border-dotted'>{get(props, 'data.label', '')}</span>
                                          </div>
                                      </components.Option>
                                  );
                              },
                          }}
                          />
                      );
                  }}
              />
            </div> 
            <div className='mt-2'>
              <Label htmlFor="keywords">Keywords</Label>
              <textarea
                  className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none h-44"
                  id="keywords"
                  placeholder={"Enter Meta Kewords"}
                  maxLength={500}
                  {...register('keywords', {
                      required: {
                          value: true,
                          message: `This field is required!`
                      },
                      validate: {
                          shouldNotContainSpace: (value) =>
                              value.trim()
                                  ? true
                                  : `This field is required!`,
                      },
                  })}
              />
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="keywords"
                    as="p"
                />
            </div>   
          </div>
          <div className='mt-2 col-span-1'>
            <Label htmlFor="photo">Banner</Label>
            <label className=' w-full h-80 border border-dotted border-gray-400 rounded-xl flex justify-center items-center flex-col cursor-pointer relative'>
              <input
                  className='w-full h-full'
                  hidden
                  type="file"
                  accept="image/*"
                  size={1048576}
                  {...register('photo')}
                />
                {
                   ((selected && watch('photo')?.[0]) || (!selected && (watch('photo')?.[0]) || current.photo)) ?
                  <img className=' absolute object-contain origin-center w-full h-full' src={(watch(`photo`)?.[0] && URL?.createObjectURL(watch(`photo`)?.[0])) || `${process.env.REACT_APP_API_URL}/course/photo/${selected}` } />
                  :
                  <div className='italic'>
                    <p className=' text-xs text-gray-600 text-center'>Upload Banner Image </p>
                    <p className='text-xs text-gray-400 mt-1'>(Only *jpg. *.jpeg and *.png images will be accepted)</p>
                    <p className=' text-red-400 text-xs mt-2 font-semibold text-center'>Limit 1Mb</p>
                  </div>
                }
            </label>
          </div>
        </div>
        
        <div>
          <Label htmlFor="metaDescription"> Description</Label>
          <textarea
              className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
              id="metaDescription"
              placeholder={"Enter Meta Description"}
              maxLength={500}
              {...register('metaDescription', {
                  required: {
                      value: true,
                      message: `This field is required!`
                  },
                  validate: {
                      shouldNotContainSpace: (value) =>
                          value.trim()
                              ? true
                              : `This field is required!`,
                  },
              })}
          />
            <ErrorMessage
                className="text-red-500 text-xs"
                errors={errors}
                name="metaDescription"
                as="p"
            />
        </div>
      
        <div className='flex justify-end'>
          <button type="submit" onClick={() => null} className=' text-sm text-white px-5 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'>{selected? 'Save' :  'Submit'}</button>
        </div>
      </form>
      {
        loading && <Spinner />
      }
    </div>
    
  )
}
