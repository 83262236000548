/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/semi */
import SearchInput from '../../components/SearchInput';
import { useDebouncedState } from '../../hooks/useDebounce';
import { PlusIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Table from '../../components/Table';
import Form from './Form';
import lessonAPI from '../../api/lessons';
import TablePagination from '../../components/Pagination';
import { useNotifier } from 'react-headless-notifier';
import { SuccessNotification } from '../../components/Notification';
import { DangerNotification } from '../../components/Notification';
import Spinner from '../../components/Spinner';

export default function Lessons() {
  const [keyword, setKeyword] = useDebouncedState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const { notify } = useNotifier();
  const [data, setData] = useState({
    currentPage: 1,
    data: [],
    totalPages: 1
  })
  
  const [loading, setLoading] = useState(true)
  const [selectedLesson, setSelectedLesson] = useState('')

  const getData = (page = 1) => {
    setLoading(true)
    const body = { page, limit: 10, search: keyword }
    lessonAPI.getLessons(body).then((res: any) => {
      if (res.data) {
        setData({...data, ...res.data})
        
      }
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  }, [keyword])

  const handleActive = (slug:string, isActive: boolean) => {
      setLoading(true)
      lessonAPI.updateLesson({ slug, isActive })
      .then(() => {
        getData()
        notify(
          <SuccessNotification
            message="Changed status success!"
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      })
      .catch(() => {
        notify(
          <DangerNotification
            message="Changed status Fail! Please try it again "
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      }).finally(() => setLoading(false))
  }

  const handleDelete = (slug:string) => {
    setLoading(true)
    lessonAPI.deleteLesson(slug)
    .then(() => {
      getData()
      notify(
        <SuccessNotification
          message="Deleted lesson success!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      );
    })
    .catch(() => 
      notify(
        <DangerNotification
          message="Deleted lesson fail! Please try it again "
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )).finally(() => setLoading(false))
  }

  const handleCreate = (data: any) => {
    setLoading(true)
    lessonAPI.createLesson(data).then(() => {
      getData()
      setOpenForm(false)
      notify(
        <SuccessNotification
          message="Created lesson success!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      );
    }).catch(() => 
      notify(
        <DangerNotification
          message="Created lesson fail! Please try it again "
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        }
      )).finally(() => setLoading(false))
  }

  const handleUpdate = (data: any) => {
    setLoading(true)
    lessonAPI.updateLesson(data)
    .then((res: any) => {
      if (res.data) {
        getData()
        setSelectedLesson('')
        notify(
          <SuccessNotification
            message="Updated lesson success!"
          />,
          {
            position: 'bottomRight',
            duration: 3000, 
          },
        );
      }
    })
    .catch(() => 
      notify(
        <DangerNotification
          message="Updated lesson fail!"
        />,
        {
          position: 'bottomRight',
          duration: 3000, 
        },
      )
    ).finally(() => setLoading(false));
  };

  const handlePagination = (nextPage: number) => {
    getData(nextPage)
  };

  return (
    <div className='relative'>
      {
        isOpenForm || selectedLesson ? 
        <Form backFn={setOpenForm} handleCreate={handleCreate} handleUpdate={handleUpdate} selected={selectedLesson} setSelected={setSelectedLesson}/>
        :
        <div>
          <div className='flex justify-between mb-4'>
            <SearchInput onSubmit={(e: any) => setKeyword(e)} />
            <button onClick={() => setOpenForm(true)} className=' text-sm text-white px-3 py-2 rounded-md shadow-sm bg-blue-400 hover:opacity-75 flex items-center'><PlusIcon className='w-4 h-4 mr-1'/> Add New Lesson</button>
          </div>
          <div className='h-[calc(100vh-200px)] w-[calc(100vw-310px)] overflow-scroll'>
            <Table data={data.data} handleActive={handleActive} handleDelete={handleDelete} setSelected={setSelectedLesson} detail="lessons"/>
          </div>
          <TablePagination 
            onClickPagination={handlePagination}
            perPage={10}
            total={+data?.totalPages * 10}
            forcePage={+data.currentPage - 1}
          />
        </div>
      }
     {
      loading && <Spinner />
     }
    </div>
  )
}
