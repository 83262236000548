/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNotifier } from "react-headless-notifier";

import Label from "../../../components/Label";
import InputMulti from "./InputMulti";
import InputBase from "../../../components/base/InputBase";
import TrashIcon from "../../../assets/svg/trash.svg";
import TrashRedIcon from "../../../assets/svg/trash-red.svg";
import { preventSpecialNumber } from "../../../utils";
import adminInsightAPI, { FormValues } from "../../../api/admin-insight";
import {
  DangerNotification,
  SuccessNotification,
} from "../../../components/Notification";

interface IProps {
  id?: string;
}

export default function FormQuestion({ id }: IProps) {
  const { notify } = useNotifier();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: id
      ? {}
      : {
          title: "Sample question",
          slug: "sample-slug",
          questions: [
            {
              question: "What 's your question?",
              answerOptions: ["A", "B", "C", "D"],
              correctAnswerIndex: 0,
              rewards: [{ amount: 10, assetType: "PCAP" }],
            },
          ],
        },
  });

  const { fields, append, remove } = useFieldArray({
    keyName: "tempId",
    control,
    name: "questions",
  });

  const submit = async (values: FormValues) => {
    if (id) {
      adminInsightAPI
        .updateLesson(values)
        .then((res) => {
          reset({
            ...res.data.data,
          });
          notify(<SuccessNotification message="Updated!" />, {
            position: "bottomRight",
            duration: 3000,
          });
        })
        .catch(() => {
          notify(
            <DangerNotification message="Updated fail! Please try it again." />,
            {
              position: "bottomRight",
              duration: 3000,
            }
          );
        });
    } else {
      adminInsightAPI
        .createLesson(values)
        .then(() => {
          notify(<SuccessNotification message="Created!" />, {
            position: "bottomRight",
            duration: 3000,
          });
          reset({
            title: "",
            slug: "",
            questions: [],
          });
        })
        .catch(() => {
          notify(
            <DangerNotification message="Created fail! Please try it again." />,
            {
              position: "bottomRight",
              duration: 3000,
            }
          );
        });
    }
  };

  const handleRemove = async (index: number, id?: string) => {
    if (id) {
      try {
        adminInsightAPI.deleteQuestion(id).then(() => {
          remove(index);
          notify(<SuccessNotification message="Deleted!" />, {
            position: "bottomRight",
            duration: 3000,
          });
        });
      } catch (err) {
        notify(
          <DangerNotification message="Deleted fail! Please try it again." />,
          {
            position: "bottomRight",
            duration: 3000,
          }
        );
      }
    } else {
      remove(index);
    }
  };

  useEffect(() => {
    if (id) {
      // setLoading(true);
      adminInsightAPI
        .getLesson(id)
        .then((res: any) => {
          console.log("res: ", res);
          reset({
            ...res.data.data,
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  }, [id]);

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
      <div className="gap-x-8 gap-y-5 mt-2 bg-white rounded-lg max-h-[84vh] overflow-scroll p-4">
        <h2 className="text-lg font-semibold">Lesson</h2>
        <div className="flex w-full gap-4">
          <div className="flex-1">
            <Label htmlFor="title">Title</Label>
            <InputBase
              type="text"
              placeholder={"Enter Title"}
              {...register("title")}
            />
          </div>

          <div className="flex-1">
            <Label htmlFor="slug">Slug</Label>
            <InputBase
              type="text"
              placeholder={"Enter Slug"}
              {...register("slug")}
            />
          </div>
        </div>
      </div>

      <div className="gap-x-8 gap-y-5 mt-2 bg-white rounded-lg max-h-[84vh] overflow-scroll p-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold">Questions</h2>
          <button
            onClick={() =>
              append(
                !fields.length
                  ? {
                      question: "What 's your question?",
                      answerOptions: ["A", "B", "C", "D"],
                      correctAnswerIndex: 0,
                      rewards: [{ amount: 10, assetType: "PCAP" }],
                    }
                  : {
                      question: "",
                      answerOptions: [],
                      correctAnswerIndex: 0,
                      rewards: [],
                    }
              )
            }
            className="px-2 py-1 rounded-md bg-slate-200"
            type="button"
          >
            + Add
          </button>
        </div>
        <div className="flex flex-col gap-2">
          {fields.map((item, index) => (
            <div key={item.tempId} className="flex gap-4 items-center">
              <div className="flex-1">
                <Label htmlFor="question">{`Question ${index + 1}`}</Label>
                <InputBase
                  type="text"
                  placeholder={"Enter Question"}
                  {...register(`questions.${index}.question` as const)}
                />
              </div>
              <div className="flex-1">
                <Label htmlFor="answerOptions">Answers</Label>
                <InputMulti
                  onChange={(value) =>
                    setValue(
                      `questions.${index}.answerOptions` as const,
                      value.map((item) => item.value)
                    )
                  }
                  defaultValue={item.answerOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  placeholder="Type and enter"
                />
              </div>
              <div className="w-24">
                <Label htmlFor="correctAnswerIndex">Correct Index</Label>
                <input
                  className="px-4 py-2.5 border h-11 border-[#dcdcdc] text-[14px] w-full text-base rounded-lg outline-none focus:border-[#97B4FF]"
                  type="number"
                  min={0}
                  placeholder="Index"
                  {...(register(
                    `questions.${index}.correctAnswerIndex` as const
                  ),
                  {
                    onChange: (e) => {
                      setValue(
                        `questions.${index}.correctAnswerIndex` as const,
                        +e.target.value
                      );
                    },
                  })}
                  onKeyDown={preventSpecialNumber}
                  defaultValue={item.correctAnswerIndex}
                />
              </div>
              <div className="w-60">
                <Label htmlFor="rewards">Rewards</Label>
                <InputMulti
                  onChange={(value) =>
                    setValue(
                      `questions.${index}.rewards` as const,
                      value.map((item) => {
                        const [amount, assetType] = item.value.split(" ");
                        return {
                          amount: +amount,
                          assetType,
                        };
                      })
                    )
                  }
                  defaultValue={item.rewards.map((reward) => ({
                    label: `${reward.amount} ${reward.assetType}`,
                    value: `${reward.amount} ${reward.assetType}`,
                  }))}
                  placeholder="Ex: 10 PCAP"
                />
              </div>
              <button
                onClick={() => handleRemove(index, item.id)}
                className="mt-5"
                type="button"
              >
                <img src={item.id ? TrashRedIcon : TrashIcon} alt="" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="drop-shadow-md inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
}
